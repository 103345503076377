import Box  from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import dynamic from 'next/dynamic';


const HeaderLogo = dynamic(() => import("./HeaderLogo"));
const HeaderSearch = dynamic(() => import("./HeaderSearch"));
const HeaderTools = dynamic(() => import("./HeaderTools"));



const HeaderBar2 = () => {
  // const { user, isAuthenticated } = useSelector((state) => state.authReducer);
  // const { cart } = useSelector((state) => state.cartReducer);
  // const [placeholder, setPlaceholder] = useState("Search")
  // const router= useRouter()


    
  return (
    <>
      <Grid container className='mt-2'>
        <Grid item xs={6} >
          <Box sx={{ boxSizing:'inherit', display:'flex'}}>
            <HeaderLogo/>
            <HeaderSearch/>
          </Box>
        </Grid>
          <Grid item xs={6} >
            <HeaderTools/>
          </Grid>
      </Grid>

    </>
  )
}

export default HeaderBar2